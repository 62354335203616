import React from 'react';
import "../../scss/main.scss"
import Header from '../../components/header';
import Main from '../../components/main';
import PageHeader from '../../components/Organism/PageHeader/PageHeader';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer/Footer';
import StartWork from '../../components/forms/StartWork';
import Benefits from "../../components/Organism/benefits/benefits";
import HowTo from "../../components/Organism/HowTo/HowTo";
import Cta from "../../components/Organism/Cta/Cta";
import Testimonials from "../../components/Organism/testimonials/testimonials";
import Portfolio from "../../components/Organism/Portfolio/Portfolio";
import Faq from "../../components/Organism/Faq/Faq";

function InternetMarket(props) {
    const form = <StartWork/>;
    return (
        <>
            <Helmet>
                <title>Разработка интернет магазинов под ключ</title>
                <meta name="description" content="Рабочий инструмент по получению заказав на ваши товары или услуги" />
            </Helmet>
            <Header/>
            <Main>
                <PageHeader h1={"Разработка интернет магазинов"} 
                    p={"Создаем E-commerce приложения так чтобы вашим покупателям было удобно"} modalButton modalContent={form}/>
            </Main>
            <Benefits/>
            <HowTo bigText="Что мы делаем для вас" header="Этапы разработки интернет магазина"/>
            <Cta/>
            <Portfolio/>

            <Faq/>
            <Footer/>
        </>
    );
}

export default InternetMarket;